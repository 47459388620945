/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'animate.css';

/* Add this to your CSS */
@font-face {
  font-family: 'popins-regular';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype'); /* Adjust the path accordingly */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'popins-bold';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype'); /* Adjust the path accordingly */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Reddit-Sans-regular';
  src: url('/assets/fonts/reddit-sans/RedditSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Reddit-Sans-bold';
  src: url('/assets/fonts/reddit-sans/RedditSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik-regular';
  src: url('/assets/fonts/rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body{
  height: unset;
  width: unset;
}

h1, h2, h3, h4, h5, h6{
  color: unset;
}

.tooltip-arrow-dir {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.8); /* Adjust color as needed */
  bottom: -5px; /* Adjust position as needed */
  left: 50%; /* Center the arrow horizontally */
  transform: translateX(-50%); /* Center the arrow horizontally */
}

input[type="password"]::-ms-reveal {
  display: none; /* For Microsoft Edge */
}

input[type="password"]::-ms-clear {
  display: none; /* For Microsoft Edge */
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.custom-header .ant-collapse-header{
  background-color: #fafafa; /* Your custom background color */
  color: rgb(8, 8, 8); /* Text color */
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
}

.ant-modal,.ant-modal-content {
  max-width: 80rem;
  margin: 20px 0px;
}

.cdk-drag-preview .ant-collapse-header{
  background-color: #fafafa;
  color: rgb(8, 8, 8);
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  color: rgba(0, 0, 0, .85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all .3s, visibility 0s;
  padding: 12px 40px 12px 16px;
}

.cdk-drag-preview .ant-collapse-extra {
  margin-left: auto;
}

.cdk-drag-preview .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
  display: inline-block;
  font-size: 12px;
  vertical-align: -1px;
}

.cdk-drag-preview .anticon {
  color: inherit;
  line-height: 0;
  text-align: center;
}

[cdkDragDragging] {
  background: #f0f0f0;
  border: 1px dashed #ccc;
  opacity: 0.8;
}

input[type="color"] {
  -webkit-appearance: none; 
  appearance: none; 
  width: 100%; 
  height: 40px; 
  border-radius: 0; 
}

.cropper-container {
  min-width: 200px;
  min-height: 200px;
}

.video-container{
  /* height: 170px;
  overflow: hidden;  */
  position: relative;
}

.video-container video{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ant-picker-input>input{
  color: white !important;
}

.ant-picker-input .anticon{
  color: white !important;
}

.ant-picker-ok .ant-btn-primary{
  background: #06b6d4 !important; 
  color: white;             
}

.ant-carousel .slick-list,.ant-carousel .slick-list .slick-track, .ant-carousel .slick-list .slick-slide{
 height: unset !important;
}

.ant-carousel .slick-list .slick-slide{
  display: flex;
  justify-content: center;
  justify-items: center;
}

.ant-carousel .slick-list .slick-track{
  display: flex;
  justify-content: center;
  align-items: center;
}


